// @flow

import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import axios, { type Axios } from 'axios'
import ClientOAuth2 from 'client-oauth2'
import url from 'url'
import PageNotFoundErrorPage from './GuidelinesNew/PageNotFoundErrorPage'
import {
  type ProgramPayload,
  fetchProgram,
  type Lot,
  fetchLotCatalog,
} from './Api'
import LoadingScreen from './GuidelinesNew/LoadingScreen'
import CustomerAreaBridge from './CustomerAreaBridge'
import CustomerContext from './CustomerAreaBridge/Context'
import RotateDevice from './GuidelinesNew/RotateDevice'
import FooterCusthome from './GuidelinesNew/FooterCusthome'
import { type REFERRER_VALUE } from './urls'
import './i18n'
import i18next from 'i18next'

const ProgramPreview = React.lazy(() => import('./ProgramPreview/UI'))
const CustomerArea = React.lazy(() => import('./CustomerArea'))
const Configurateur3DApplication = React.lazy(() =>
  import('./FlatConfigurator/Configurateur3DApplication'),
)
const ConfigurationScreenshotCapture = React.lazy(() =>
  import('./ConfigurationScreenshotCapture'),
)

type Props = {|
  apiEndpoint: string,
  programHostname: string,
  oauthClientId: string,
  oauthClientSecret: string,
|}

type State = {|
  httpClient: Axios,
  authClient: typeof ClientOAuth2,
  program: ?ProgramPayload,
  lotCatalog: ?(Lot[]),
|}

class MainApplication extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const httpClient = axios.create({
      baseURL: props.apiEndpoint,
    })

    // The browser version of the `url` module implement the deprecated version of the `url` API
    const accessTokenUri = url.format({
      ...url.parse(props.apiEndpoint || '/'), // eslint-disable-line node/no-deprecated-api
      ...{ pathname: '/oauth/v2/token' },
    })

    const authClient = new ClientOAuth2({
      clientId: props.oauthClientId,
      clientSecret: props.oauthClientSecret,
      accessTokenUri,
      scopes: [],
    })

    this.state = {
      httpClient,
      authClient,
      program: null,
      lotCatalog: null,
    }
  }

  async componentDidMount() {
    const { httpClient } = this.state
    const { programHostname } = this.props

    httpClient.interceptors.request.use(config => ({
      ...config,
      params: {
        locale: i18next.language,
      },
    }))

    const program = await fetchProgram(programHostname)
    document.title = program.label

    const lotCatalog = await fetchLotCatalog(program.links.lot_catalog)

    this.setState({ program, lotCatalog })
  }

  render() {
    const { httpClient, authClient, program, lotCatalog } = this.state

    if (!program || !lotCatalog) {
      return <LoadingScreen isVisible />
    }

    return (
      <CustomerAreaBridge
        httpClient={httpClient}
        authClient={authClient}
        programId={program.programId}
        privacyPoliciesPageUrl={program.links.privacy_policy}
        termsOfServicesPageUrl={program.links.terms_of_use}
      >
        <FooterCusthome />
        <RotateDevice />
        <React.Suspense fallback={<LoadingScreen isVisible />}>
          <Switch>
            {/* Redirect to the homepage. Only for development environment. The production redirection is configured in the now.json file */}
            <Route exact path="/" render={() => <Redirect to="/program" />} />

            <Route
              exact
              path="/program"
              render={() => (
                <CustomerContext.Consumer>
                  {customerContextApi => (
                    <ProgramPreview
                      httpClient={httpClient}
                      customerContextApi={customerContextApi}
                      program={program}
                      lotCatalog={lotCatalog}
                    />
                  )}
                </CustomerContext.Consumer>
              )}
            />

            <Route
              exact
              path="/lot/:lotId"
              render={props => {
                const searchParams = new URLSearchParams(props.location.search)
                const configurationId = searchParams.get('configurationId')

                const referrer: REFERRER_VALUE =
                  // $FlowFixMe: Convert a string to an enum is impossible in flow
                  searchParams.get('referrer') || null

                return (
                  <CustomerContext.Consumer>
                    {customerContextApi => (
                      <Configurateur3DApplication
                        httpClient={httpClient}
                        customerContextApi={customerContextApi}
                        program={program}
                        lotCatalog={lotCatalog}
                        lotId={props.match.params.lotId}
                        configurationId={configurationId}
                        referrer={referrer}
                        history={props.history}
                      />
                    )}
                  </CustomerContext.Consumer>
                )
              }}
            />

            {process.env.REACT_APP_DISABLE_CLIENT_AREA !== 'true' && (
              <Route
                path="/customer"
                render={() => (
                  <CustomerContext.Consumer>
                    {customerContextApi => (
                      <CustomerArea
                        httpClient={httpClient}
                        customerContextApi={customerContextApi}
                        privacyPoliciesPageUrl={program.links.privacy_policy}
                        programDeveloperName={program.developerName}
                        programLabel={program.label}
                        programLocation={program.location}
                        programLotsNumber={program.numberOfLots}
                      />
                    )}
                  </CustomerContext.Consumer>
                )}
              />
            )}

            <Route
              exact
              path="/screenshot/:lotId"
              render={props => (
                <ConfigurationScreenshotCapture
                  httpClient={httpClient}
                  program={program}
                  lotCatalog={lotCatalog}
                  lotId={props.match.params.lotId}
                />
              )}
            />

            <Route render={() => <PageNotFoundErrorPage />} />
          </Switch>
        </React.Suspense>
      </CustomerAreaBridge>
    )
  }
}

export default (MainApplication: React.ComponentType<Props>)
